<template>
  <div class="col-10 offset-1 h-100">
    <div class="row no-gutters pt-2 pb-2">
      <div class="col-md-6">
        <p class="m-list-title">Importazioni</p>
        <p>
          <small
            >Vedi file
            <strong
              ><a
                href="../import_file_example.csv"
                target="_blank"
                class="text-primary"
              >
                modello (.csv)
              </a></strong
            >
          </small>
        </p>
      </div>
    </div>
    <b-table
      :items="getItems"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
      small
      striped
      id="AppImportTable"
      thead-tr-class="m-table"
      :tbody-tr-class="rowClass"
    >
      <template #cell(data)="row">
        {{ chageFormatDate(row.item.createdDt) }}
      </template>

      <template #cell(nome)="row">
        {{ row.item.filePath }}
      </template>

      <template #cell(numberContact)="row">
        <span class="text-success">{{ row.item.contactsSuccessNr }}</span>
        /
        <span class="text-danger">{{ row.item.contactsErrorNr }}</span>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          :to="{
            name: 'DettagliImportazione',
            query: { id: row.item.id },
          }"
          class="mr-1"
        >
          Dettagli
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="getRow"
      size="sm"
      :per-page="perPage"
      aria-controls="AppImportTable"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["getItems", "getRow"],
  name: "AppImportTable",
  data() {
    return {
      fields: [
        {
          key: "data",
          label: "Importazione del",
          sortable: true,
          thClass: "",
          tdClass: "d-flex justify-content-start ",
        },
        {
          key: "nome",
          label: "Nome file",
          sortable: false,
        },
        {
          key: "contactsNr",
          label: "Da Importare ",
          sortable: true,
        },
        {
          key: "numberContact",
          label: "Importati / Errore",
          sortable: true,
        },

        {
          key: "actions",
          label: "",
          sortable: false,
          thClass: "",
          tdClass: "d-flex justify-content-end ",
        },
      ],

      //Inizializazione tabella
      sortBy: "Ord",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
    };
  },

  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status !== "OK") return "table-danger";
    },

    chageFormatDate($date) {
      return moment
        .utc(new Date($date), "YYYY-MM-DD HH:mm:ss")
        .local()
        .format("DD/MM/YYYY HH:mm:ss");
    },
  },
};
</script>
